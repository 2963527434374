import { Controller } from '@hotwired/stimulus'
import { useTransition } from 'stimulus-use'

export default class extends Controller {
  static targets = ['menu', 'trigger']

  connect() {
    // console.log("dropdown controller")
    useTransition(this, {
      element: this.menuTarget,
    })
  }

  toggle() {
    // console.log("dropdown controller toggle")
    this.toggleTransition()
  }

  hide(event) {
    // console.log("dropdown controller hide")
    if (
      !this.element.contains(event.target) &&
      !this.menuTarget.classList.contains('hidden')
    ) {
      this.leave()
    }
  }
}
