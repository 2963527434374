import { Controller } from "@hotwired/stimulus"
import f3 from "../family-chart/index.js"

// Connects to data-controller="tree"
export default class extends Controller {
  static targets = ["tree"]

  static values = {
    data: Array
  }

  connect() {
    // console.log(" tree_controller connected")
    // console.log(this.dataValue)
    const data = this.dataValue
      const store = f3.createStore({
        data,
        node_separation: 250,
        level_separation: 150
      }),
        view = f3.d3AnimationView({
          store,
          cont: document.querySelector("#FamilyChart")
        }),
        Card = f3.elements.Card({
          store,
          svg: view.svg,
          card_dim: { w: 220, h: 70, text_x: 75, text_y: 15, img_w: 60, img_h: 60, img_x: 5, img_y: 5 },
          card_display: [d => d.data.fn || '', d => d.data.ln || '', d => d.data.desc || ''],
          mini_tree: true,
          link_break: false
        })

      view.setCard(Card)
      store.setOnUpdate(props => view.update(props || {}))
      store.update.tree({ initial: true })

      this.dispatchReady()
  }

  dispatchReady() {
    this.dispatch("ready", { detail: {  } })
  }

}
