import { Controller } from "stimulus"
import debounce from "lodash/debounce"

export default class extends Controller {
  static targets = ["form", "input"]

  connect() {
    this.submit = debounce(this.submit.bind(this), 200);
  }

  submit() {
    // console.log("form#submit");
    this.formTarget.requestSubmit();
  }

  reset() {
    // console.log("form#reset");
    this.inputTarget.value = "";
    this.formTarget.requestSubmit();
  }
}