import { Controller } from "@hotwired/stimulus"
import { Chart, LinearScale } from 'chart.js'
import { WordCloudController, WordElement } from 'chartjs-chart-wordcloud'

// Connects to data-controller="cloud"
export default class extends Controller {

  static targets = ["cloud"]
  static values = { words: Array }

  connect() {
    // console.log(" cloud_controller connected")
    // console.log(this.wordsValue)

    // const words = [
    //   { key: "word", value: 30 },
    //   { key: "words", value: 1 },
    //   { key: "sprite", value: 7 },
    //   { key: "placed", value: 5 }
    // ]

    Chart.register(WordCloudController, WordElement, LinearScale)

    const chart = new Chart(document.getElementById("canvas").getContext("2d"), {
      type: WordCloudController.id,
      data: {
        labels: this.wordsValue.map((d) => d.key),
        datasets: [
          {
            label: "",
            data: this.wordsValue.map((d) => 10 + d.value * 10)
          }
        ]
      },
      options: {
        elements: {
          word: {
            // color: "blue",
            family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            // hoverColor: "red",
            // strokeStyle: 'green',
            maxRotation: 45,
            minRotation: -45,
            rotationSteps: 5,
            strokeWidth: 1,
            padding: 1
          },
        },
        title: {
          display: false,
          text: "foo"
        },
        plugins: {
          chartAreaBorder: {
            borderColor: 'red',
            borderWidth: 2,
            borderDash: [5, 5],
            borderDashOffset: 2,
          },
          legend: {
            display: false
          }
        }
      }
    })

  }
}
